.Home{
  height: 100vh;
  display: flex;
  .ant-layout-sider{
    background: #3836A8;
    max-width: 100px !important;
    min-width: 100px !important;
    width: 100px !important;
  }
  .ant-layout-header{
    background: #FFF;
    border: none;
    box-shadow: 0px 0px 4px rgba($color: #000000, $alpha: 0.1);
    padding: 0 40px 0 22px; 
  }
  .main-content.ant-layout-content{
    background: #F2F2F2;
    padding: 10px;
  }
  .menu-slider{
    .logo{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      margin: 40px auto 20px;
    }
  }
}
