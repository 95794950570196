.ant-layout{
  background: #FFF;
}

.link{
  color: #1677ff;
  cursor: pointer;
}

.list-page{
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #FFF;
  border-radius: 2px;
  box-shadow: 0px 0px 4px rgba($color: #000000, $alpha: 0.1);
  .ant-table-wrapper{
    height: 100%;
  }
  .ant-spin-nested-loading{
    height: 100%;
  }
  .ant-spin-container{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-table{
    flex: 1;
  }
  .ant-table-container{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-table-body{
    flex: 1;
    tr.selected{
      td.ant-table-cell,
      td.ant-table-cell-row-hover{
        background: #BFEFFF;
      }
    }
  }
  
}


