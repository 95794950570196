.Login{
  background: #3836A8 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 1280px;
  overflow: auto;
  .container{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('./bg.png') center center no-repeat;
    background-size: 100%;
  }

  .main-area{
    color: #FFF;
    margin-right: 110px;
    .welcome{
      font-family: AlibabaPuHuiTiL;
      font-size: 52px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 60px;
      font-weight: 200;
    }
    .platform-name{
      font-family: AlibabaPuHuiTiB;
      font-size: 52px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 60px;
      font-weight: 700;
      margin: 15px 0 35px;
    }
    .platform-name-en{
      opacity: 0.4;
      font-family: AlibabaPuHuiTiR;
      font-size: 26px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 30px;
      font-weight: 400;
    }
  }

  .login-form{
    width: 300px;
    padding: 20px;
    border-radius: 2px;
    background: #FFF;
    border: 1px solid #CCC;
    .logo-area{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px 0 40px;
      .logo{
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
      }
      .text{
        font-family: PingFang-SC-Regular;
        font-size: 12px;
        color: #191919;
        letter-spacing: 0.89px;
        text-align: center;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
  .footer{
    padding: 20px;
    color: #DDD;
    display: flex;
    align-items: center;
    justify-content: center;
    .icp{
      margin-left: 20px;
      color: #DDD;
      &:hover{
        text-decoration: underline;
        color: #FFF;
        cursor: pointer;
      }
    }
  }
}