.hz-header{
  display: flex;
  justify-content: space-between;
  .bread-area{
    display: flex;
    align-items: flex-end;
  }
  .user-center{
    display: flex;
    align-items: center;
    .user-name{
      margin: 0 10px;
      font-size: 14px;
      color: #333;
    }
  }
}