.hz-menu{
  .ant-menu{
    background: none;
    color: #FFF;
    font-size: 13px;
  }
  .ant-menu-item,
  .ant-menu-submenu{
    height: 86px;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8786CA;
    &.ant-menu-item-selected,
    &.ant-menu-item-active{
      opacity: 1;
      background: none;
      color: #FFF;
      .label{
        color: #FFF;
      }
      .menu-icon{
        display: none;
      }
      .menu-icon-on{
        display: block;
      }
    }
    &>span{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .menu-icon,
      .menu-icon-on{
        width: 30px;
        height: 30px;
        overflow: hidden;
        margin: 0 auto;
      }
      .menu-icon-on{
        display: none;
      }
    }
  }
}

.child-menu{
  .child-menu-item{
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    &:hover{
      background: #322F9F;
      color: #FFF;
    }
  }
}