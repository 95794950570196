.welcome-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bg-img{
    width: 240px;
  }

  .title{
    font-size: 20px;
    color: #191919;
    text-align: center;
    font-weight: 600;
    margin: 20px 0 20px;
  }

  .content{
    opacity: 0.66;
    font-size: 14px;
    color: #666666;
    text-align: center;
    font-weight: 400;
  }

}