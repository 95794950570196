.dict-edit-item{
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  .container{
    flex: 1;
    display: flex;
    margin-top: 20px;
    .list-area{
      width: 50%;
    }
    .form-aera{
      flex: 1;
      .form-title{
        margin: 0 0 20px;
        font-size: 16px;
        color: #333;
        text-align: center;
      }
    }
  }
}